<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.777" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g data-name="icon deadline" id="icon_deadline" transform="translate(-2442 -2618)">
    <g data-name="Group 469" id="Group_469" transform="translate(-228 263)">
     <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(2670 2355)" width="183"/>
    </g>
    <g data-name="MDI / calendar-clock" id="MDI_calendar-clock" style="isolation: isolate" transform="translate(2471.682 2647.682)">
     <g id="Boundary" opacity="0">
      <rect :fill="background" data-name="Boundary" height="123.636" id="Boundary-2" width="123.636"/>
      <path d="M1,1V122.636H122.636V1H1M0,0H123.636V123.636H0Z" data-name="Boundary - Outline" fill="rgba(0,0,0,0)" id="Boundary_-_Outline"/>
     </g>
     <path d="M64.818,62.818h7.727V77.345l12.57,7.264-3.864,6.7L64.818,81.827V62.818M85.424,37.06H13.3V93.727H37.361A36.109,36.109,0,0,1,85.424,45.664v-8.6M13.3,104.03A10.3,10.3,0,0,1,3,93.727V21.606A10.267,10.267,0,0,1,13.3,11.3h5.151V1h10.3V11.3H69.969V1h10.3V11.3h5.151a10.3,10.3,0,0,1,10.3,10.3V53.03a36.064,36.064,0,0,1-51,51H13.3M69.969,53.288A24.985,24.985,0,1,0,94.954,78.272,24.985,24.985,0,0,0,69.969,53.288Z" data-name="Path / calendar-clock" fill="url(#linear-gradient)" id="Path_calendar-clock" transform="translate(7.303 4.151)"/>
     <path d="M69.969,114.833a36.346,36.346,0,0,1-25.446-10.3H13.3a10.8,10.8,0,0,1-10.8-10.8V21.606A10.767,10.767,0,0,1,13.3,10.8h4.652V.5h11.3V10.8H69.469V.5h11.3V10.8h4.651a10.8,10.8,0,0,1,10.8,10.8v31.22a36.552,36.552,0,0,1-26.257,62.007ZM13.3,11.8a9.767,9.767,0,0,0-9.8,9.8V93.727a9.8,9.8,0,0,0,9.8,9.8H44.932l.146.144A35.564,35.564,0,0,0,95.371,53.381l-.144-.146V21.606a9.814,9.814,0,0,0-9.8-9.8H79.772V1.5h-9.3V11.8H28.257V1.5h-9.3V11.8Zm56.667,91.954a25.407,25.407,0,1,1,9.92-2A25.346,25.346,0,0,1,69.969,103.757Zm0-49.97A24.406,24.406,0,1,0,79.5,55.711,24.331,24.331,0,0,0,69.969,53.788ZM38.15,94.227H12.8V36.56H85.924v9.892l-.714-.337A35.49,35.49,0,0,0,77.9,43.595a35.955,35.955,0,0,0-7.926-.883A35.53,35.53,0,0,0,34.409,78.272a35.954,35.954,0,0,0,.883,7.926,35.49,35.49,0,0,0,2.521,7.315Zm-24.347-1H36.581a36.516,36.516,0,0,1-2.264-6.808,36.956,36.956,0,0,1-.907-8.147A36.53,36.53,0,0,1,69.97,41.712a36.956,36.956,0,0,1,8.147.907,36.514,36.514,0,0,1,6.808,2.264V37.56H13.8Zm67.632-1.238L64.318,82.116v-19.8h8.727V77.057L85.8,84.426ZM65.318,81.538l15.75,9.085,3.364-5.831L72.045,77.634V63.318H65.318Z" data-name="Path / calendar-clock - Outline" fill="rgba(0,0,0,0)" id="Path_calendar-clock_-_Outline" transform="translate(7.303 4.151)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>